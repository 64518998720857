import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import APIClient from '../../client'
import HeaderView from '../../widgets/header'
import UserMenuView from '../../widgets/usermenu'
import FooterView from '../../widgets/footer'

import ReCAPTCHA from 'react-google-recaptcha'
import moment from 'moment'
import S from 'string'
import _ from 'underscore'

import AsyncSelect from 'react-select/async'

import './style.css'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import {
  API_BASE,
  CERTIFICATE_PRICE_IN_ZAR,
  PAYFAST_MERCHANT_ID,
  PAYFAST_MERCHANT_KEY,
  WEB_BASE,
  RECAPTCHA_SITE_KEY
} from '../../constants'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlaneDeparture,
  faPlaneArrival,
  faUser
} from '@fortawesome/free-solid-svg-icons'

const CHECK_INTERVAL = 1000 * 1 // 90 second, 1min 30s
class View extends Component {
  constructor (params) {
    super(params)
    let userpermissions = (params.user || {}).permissions || []
    let localcountry = (params.locality || {}).country || {}
    this.state = {
      item: null,

      countryFromCode: localcountry.code || null,
      countryFromName: localcountry.name || null,

      countryToCode: null,
      countryToName: null,

      allowfreecertificate: userpermissions.indexOf('freecertificates') !== -1,

      shipperName: null,
      shipperUID: null,

      certificatetype: 'personal',
      certificateprice: 0,
      locality: params.locality,
      user: params.user,
      searchuid: (params.match.params || {}).searchuid || null,
      section: 'loading',
      submitting: false,
      errorMessage: null
    }
    this.handleUserChange = params.handleUserChange
    this.inputName = React.createRef()
    this.inputMessage = React.createRef()
    this.inputPhonenumber = React.createRef()
    this.inputEmail = React.createRef()

    this.inputCaptcha = React.createRef()

    this.inputCertificateName = React.createRef()
    this.inputCertificateEmail = React.createRef()
    this.inputCertificatePhonenumber = React.createRef()
    this.inputCertificateComments = React.createRef()
  }
  performCaptha = async e => {
    // return ''
    if (e) e.preventDefault()
    this.inputCaptcha.current.execute()
    let value = null
    let attempts = 0
    while (!value) {
      attempts++
      try {
        value = this.inputCaptcha.current.getValue()
        if (attempts > 30) {
          return false
        }
      } catch (err) {
        console.error(err)
      }
      await new Promise(resolve => setTimeout(resolve, 1000))
    }
    this.inputCaptcha.current.reset()
    return value
  }
  renderContactDetailsForm = function () {
    // if(this.state.user) return null
    return (
      <div>
        <div className='details-box-control'>
          <div className='details-box-control-label'>
            <label htmlFor='nickname'>Name</label>
          </div>
          <div className='details-box-control-inputs'>
            <input
              type='text'
              placeholder='your full name here'
              ref={this.inputCertificateName}
              defaultValue={this.state.user ? this.state.user.name : ''}
            />
          </div>
          <div className='clearfix'></div>
        </div>

        <div className='details-box-control'>
          <div className='details-box-control-label'>
            <label htmlFor='nickname'>
              Email (Certificate will be sent here)
            </label>
          </div>
          <div className='details-box-control-inputs'>
            <input
              type='text'
              placeholder='youremail@example.com'
              ref={this.inputCertificateEmail}
              defaultValue={this.state.user ? this.state.user.email : ''}
            />
          </div>
          <div className='clearfix'></div>
        </div>

        <div className='details-box-control'>
          <div className='details-box-control-label'>
            <label htmlFor='nickname'>Phonenumber</label>
          </div>
          <div className='details-box-control-inputs'>
            <input
              type='text'
              placeholder='for example: +27 11 111 1111'
              ref={this.inputCertificatePhonenumber}
              defaultValue={this.state.user ? this.state.user.phonenumber : ''}
            />
          </div>
          <div className='clearfix'></div>
        </div>
      </div>
    )
  }
  fetch = async () => {
    // get the token
    let token = APIClient.getToken()

    // set the call
    let result = null

    // handle errors
    try {
      result = await APIClient.call({
        path: '/searches/' + this.state.searchuid,
        query: {
          token
        }
      })
    } catch (err) {
      this.setState({
        section: 'error'
      })
      return
    }

    if (result.ok !== true && result.status === 401) {
      this.setState({
        section: 'expired'
      })
      return
    }

    if (result.ok !== true && result.status === 404) {
      this.setState({
        section: 'notfound'
      })
      return
    }

    if (result.ok !== true) {
      this.setState({
        section: 'error'
      })
      return
    }

    let combineditem = this.combineResponses(result.data.responses)

    let section = 'view' // CHANGE THIS

    if (
      this.getFieldValuesByKey('name', combineditem).length === 0 &&
      this.getFieldValuesByKey('tel', combineditem).length === 0 &&
      this.getFieldValuesByKey('species', combineditem).length === 0 &&
      this.getFieldValuesByKey('breed', combineditem).length === 0
    ) {
      section = 'partial'
    }

    if (result.data.status === 'busy') {
      if ((result.data.responses || []).length <= 0) {
        section = 'busy'
      }
      this.timer = setTimeout(this.fetch, CHECK_INTERVAL)
    } else if ((result.data.responses || []).length <= 0) {
      section = 'empty'
    }

    // set the groups
    document.title = result.data.chipnumber

    this.setState({
      section: section,
      item: result.data,
      groupeditem: combineditem
    })
  }
  componentDidMount = async () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

    // set the groups
    document.title = '#' + this.state.searchuid

    // try to fetch
    this.fetch()
  }
  cleanSlugParse = text => {
    let value = S(text || '').slugify().s
    value = value.split('-').join('')
    value = value.split('_').join('')
    return value
  }
  combineResponses = responses => {
    let fields = [
      'label',
      'species',
      'breed',
      'image',
      'dob',
      'name',
      'email',
      'tel',
      'cel',
      'fax',
      'description',
      'flagged',
      'message'
    ]
    let groupings = []
    for (let i = 0; i < responses.length; i++) {
      let response = responses[i]
      for (let a = 0; a < fields.length; a++) {
        let field = fields[a]
        if (S(response[field]).isEmpty() === true) {
          continue
        }
        let grouping = null
        let groupingIndex = -1
        for (let y = 0; y < groupings.length; y++) {
          if (groupings[y].field !== field) continue
          if (S(groupings[y].value || '').isEmpty() === true) continue
          if (
            this.cleanSlugParse(groupings[y].value || '') !==
            this.cleanSlugParse(response[field] || '')
          ) {
            continue
          }
          grouping = groupings[y]
          groupingIndex = 1 * y
        }
        if (grouping) {
          groupings[groupingIndex].databases.push(response.group)
        } else {
          groupings.push({
            field: field,
            value: response[field],
            databases: [response.group || response.database]
          })
        }
      }
    }
    return groupings
  }
  getDatabaseByUID = databaseuid => {}
  submitFormFromObject = async data => {
    // Create a new form element
    const form = document.createElement('form')
    form.method = 'POST'
    form.action =
      process.env.NODE_ENV === 'production'
        ? 'https://www.payfast.co.za/eng/process'
        : 'https://sandbox.payfast.co.za​/eng/process'
    form.style.display = 'none'

    // Iterate over the object keys and values
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = key
        input.value = data[key]
        form.appendChild(input)
      }
    }

    // Append the form to the body
    document.body.appendChild(form)

    // Submit the form
    form.submit()

    // Remove the form from the document after submission
    document.body.removeChild(form)
  }
  handleCertificateSubmit = async e => {
    if (e) e.preventDefault()
    if (this.state.submitting === true) return
    this.setState({ submitting: true })

    let name = this.inputCertificateName.current.value
    let email = this.inputCertificateEmail.current.value
    let phonenumber = this.inputCertificatePhonenumber.current.value

    let captchacode = null
    try {
      captchacode = await this.performCaptha()
    } catch (err) {
      console.error(err)
      this.setState({
        submitting: false,
        errorMessage: 'Problem performing bot prevention checks'
      })
      return
    }

    this.setState({
      section: 'loading'
    })

    let postData = {
      search: this.state.item.uid,
      purpose: this.state.certificatetype,

      name,
      email,
      phonenumber,
      // comments: this.inputCertificateComments.current.value,
      comments: '',

      origin: this.state.countryFromCode,
      destination: this.state.countryToCode,

      shipper: this.state.shipperUID,

      code: this.state.item.certificatecode,
      captchacode
    }

    // get the token
    let token = APIClient.getToken()
    if (token) postData.token = token

    let result = null
    try {
      result = await APIClient.call({
        path: '/certificates',
        method: 'post',
        form: postData
      })

      if (!result.ok) {
        this.setState({
          section: 'request',
          submitting: false,
          errorMessage: result.data.message || 'Something went wrong'
        })
        return
      }
    } catch (err) {
      console.error(err)
      this.setState({
        section: 'request',
        submitting: false,
        errorMessage: 'Something went wrong, try again in a few seconds...'
      })
      return
    }

    if (this.state.certificateprice > 0) {
      // payment required so process that
      this.submitFormFromObject({
        merchant_id: PAYFAST_MERCHANT_ID,
        merchant_key: PAYFAST_MERCHANT_KEY,

        return_url: `${WEB_BASE}/sent`,
        cancel_url: `${WEB_BASE}/rejected`,
        notify_url:
          process.env.NODE_ENV === 'production'
            ? `${API_BASE}/callback`
            : 'https://9367-2001-14ba-2be8-d100-1dc0-5e23-34f0-40b.ngrok-free.app',

        amount: CERTIFICATE_PRICE_IN_ZAR,
        item_name: `ChipnDoodle Certificate`,
        item_description: `Certificate for ${this.state.item.chipnumber}`,

        m_payment_id: this.state.item.certificatecode,

        email_confirmation: '0'
        // signature: this.state.item.certificatepaysignature
      })
      return
    }

    document.location = '/sent'
  }
  handleSubmit = async e => {
    if (e) e.preventDefault()
    if (this.state.submitting === true) return
    // let token = APIClient.getToken();
    this.setState({
      submitting: true,
      submissions: this.state.submissions + 1,
      errorMessage: null
    })
    let result = null
    try {
      result = await APIClient.call({
        path: '/searches/' + this.state.item.uid,
        method: 'post',
        form: {
          action: 'contact',
          name: this.inputName.current.value,
          email: this.inputEmail.current.value,
          phonenumber: this.inputPhonenumber.current.value,
          message: this.inputMessage.current.value
        }
      })
    } catch (err) {
      this.setState({
        submitting: false,
        errorMessage: 'Something went wrong, try again in a few seconds...'
      })
      return
    }

    if (result.ok === false) {
      this.setState({
        submitting: false,
        errorMessage: result.data.message
      })
      return
    }

    this.setState({
      submitting: false,
      item: result.data,
      section: 'empty',
      errorMessage: null
    })
  }
  getFieldValuesByKey = (key, groupedfields) => {
    let vals = []
    let items = groupedfields || this.state.groupeditem || []
    for (let i = 0; i < items.length; i++) {
      let item = items[i]
      if (item.field !== key) continue
      vals.push(item.value)
    }
    return vals
  }
  getFieldsByKey = key => {
    let vals = []
    for (let i = 0; i < (this.state.groupeditem || []).length; i++) {
      let item = this.state.groupeditem[i]
      if (item.field !== key) continue
      vals.push(item)
    }
    return vals
  }
  renderField = opts => {
    let classNames = ['details-box-control']
    if (opts.half === true) {
      classNames.push('details-box-control-half')
    }
    let fieldcounter = 0
    let groupItems = this.state.groupeditem.map(groupedfield => {
      if (groupedfield.field !== opts.field) return null
      fieldcounter++
      if (opts.limit && fieldcounter > opts.limit) return null
      return (
        <p
          style={{ cursor: 'default' }}
          className={'add-details-box-control-selectable active'}
          title={
            'Information from ' +
            _.pluck(groupedfield.databases, 'name').join(',')
          }
        >
          {groupedfield.value}
        </p>
      )
    })
    if (fieldcounter === 0) {
      groupItems = (
        <p
          style={{ cursor: 'default' }}
          className={'add-details-box-control-selectable'}
        >
          NOT FOUND
        </p>
      )
    }
    return (
      <div className={classNames.join(' ')}>
        <div className='details-box-control-label'>
          <label htmlFor='nickname'>{opts.label}</label>
          {opts.description ? <p>{opts.description}</p> : null}
        </div>
        <div className='add-details-box-control-inputs'>
          <div className='add-details-box-control-selectables'>
            {groupItems}
            <div className='clearfix'></div>
          </div>
        </div>
        <div className='clearfix'></div>
      </div>
    )
  }
  fetchShippersForSelect = async (inputValue, callback) => {
    // get the token
    let token = APIClient.getToken()

    let result = null
    let entries = []
    try {
      result = await APIClient.call({
        path: '/shippers',
        method: 'get',
        query: {
          q: inputValue,
          token: token
        }
      })
    } catch (err) {
      console.error(err)
      this.setState({
        submitting: false,
        errorMessage: 'Something went wrong, try again in a few seconds...'
      })
      return
    }

    if (result.ok === false) {
      this.setState({
        submitting: false,
        errorMessage: result.data.message
      })
      return
    }

    // loop and add
    for (let i = 0; i < result.data.length; i++) {
      entries.push({
        label: result.data[i].name,
        value: result.data[i].uid
      })
    }

    callback(entries)
  }
  fetchCountriesForSelect = async (inputValue, callback) => {
    // get the token
    let token = APIClient.getToken()

    let result = null
    let entries = []
    try {
      result = await APIClient.call({
        path: '/countries',
        method: 'get',
        query: {
          q: inputValue,
          token: token
        }
      })
    } catch (err) {
      console.error(err)
      this.setState({
        submitting: false,
        errorMessage: 'Something went wrong, try again in a few seconds...'
      })
      return
    }

    if (result.ok === false) {
      this.setState({
        submitting: false,
        errorMessage: result.data.message
      })
      return
    }

    // loop and add
    for (let i = 0; i < result.data.length; i++) {
      entries.push({
        label: result.data[i].name,
        value: result.data[i].code
      })
    }

    callback(entries)
  }
  formatDescription = item => {
    if (item.website) {
      let text = item.website
      text = text.toLowerCase()
      text = text.replace(/http:\/\/www\./gi, '')
      text = text.replace(/https:\/\/www\./gi, '')
      text = text.replace(/http:\/\//gi, '')
      text = text.replace(/https:\/\//gi, '')
      text = text.split('/')[0]
      return text
    } else {
      return 'since ' + moment(item.created).format('LLL')
    }
  }
  renderSupplierBox = () => {
    if (!this.state.item) return
    console.log(this.state.item.suppliers)
    if (!this.state.item.suppliers) return
    console.log(this.state.item.suppliers)
    if (this.state.item.suppliers.length === 0) return
    return (
      <div className='details-box details-box-top'>
        <div className='details-box-meta details-box-meta-full'>
          <h3 className='search-certificate-meta-heading'>
            Supplier of Microchip
          </h3>
          <div className='search-supplier-content'>
            <p>
              According to the microchip number{' '}
              <strong>{this.state.item.chipnumber}</strong> was mostly likely
              supplied by the following providers we recommend you contact them
              for more information:
            </p>
          </div>
          <div className='search-supplier-groups'>
            {this.state.item.suppliers.map(tt => {
              return (
                <div className='search-supplier-group'>
                  <div className='search-supplier-group-preview'>
                    <img
                      alt={'Logo of ' + tt.name}
                      src={
                        tt.logo ||
                        'https://cdn.chipndoodle.com/defaults/record.png'
                      }
                    />
                  </div>
                  <div className='search-supplier-group-meta'>
                    <h3 className='search-supplier-group-meta-name'>
                      {tt.name}
                    </h3>
                    {/* <p className='provideritem-listing-item-meta-desc'>
                      {tt.description}
                    </p> */}
                    {tt.website ? (
                      <p className='search-supplier-group-meta-website'>
                        <a href={tt.website}>{tt.website}</a>
                      </p>
                    ) : null}
                    {tt.phonenumber ? (
                      <p className='search-supplier-group-meta-phonenumber'>
                        <a href={`tel:${tt.phonenumber}`}>{tt.phonenumber}</a>
                      </p>
                    ) : null}
                    {tt.email ? (
                      <p className='search-supplier-group-meta-email'>
                        <a href={`mailto:${tt.email}`}>{tt.email}</a>
                      </p>
                    ) : null}
                  </div>
                  <div className='clearfix'></div>
                </div>
              )
            })}
            <div className='clearfix'></div>
          </div>
          <div className='clearfix'></div>
        </div>
      </div>
    )
  }
  renderCertificateBox = () => {
    if (!this.state.item) return
    if (this.state.item.found <= 0) return
    let chipnumber = '' + this.state.item.chipnumber
    if (chipnumber.length !== 15) return
    // if (!this.state.user) return
    // if (
    //   (this.state.user.permissions || '').indexOf('admin') === -1 &&
    //   (this.state.user.permissions || '').indexOf('searches.certificate') === -1
    // ) {
    //   return
    // }
    return (
      <div className='details-box details-box-top'>
        <div className='details-box-meta details-box-meta-full'>
          <h3 className='search-certificate-meta-heading'>
            ChipnDoodle Certificate
          </h3>
          <img
            className='search-certificate-bg'
            src='https://cdn.chipndoodle.com/assets/certificate.svg'
            alt='Icon of certificate'
          />
          <div className='search-certificate-meta'>
            <p>
              Our certificates are a great way to show the pet with their
              registered databases globally (among our partners) along with
              their available details on all those databases where available.
            </p>
            <p>
              Add your own details and we will provide a certificate showing
              registrations over all registered partners which can be used as
              proof for exporting/importing; the certificate requires that you
              pull individual certificates from each listed provider before
              becoming valid.
            </p>
            <a
              className='search-certificate-cta '
              title='Click to proceed and make the certificate'
              href='#'
              onClick={e => {
                e.preventDefault()
                this.setState({
                  section: 'request'
                })
              }}
            >
              Request a Certificate
            </a>
            <p className='search-certificate-subslogan'>
              free for personal/archival use, priced at R
              {CERTIFICATE_PRICE_IN_ZAR} per certificate for import/export
            </p>
          </div>
          <div className='clearfix'></div>
        </div>
      </div>
    )
  }
  render () {
    let buildImagePreview = null
    let buildImageTitle = null
    let images = this.getFieldsByKey('image')
    if (images.length > 0) {
      buildImagePreview = images[0].value || null
      if (images[0].databases[0]) {
        buildImageTitle = 'Image provided by ' + images[0].databases[0].name
      }
    }
    if (this.state.section === 'loading') {
      return (
        <div className='wrapper initialoading-wrapper-bg'>
          <HeaderView
            {...this.props}
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='initialoading-inside-wrapper'>
            <div className='initialoading'>
              <div className='dots'>
                <div className='dot dot1'></div>
                <div className='dot dot2'></div>
                <div className='dot dot3'></div>
                <div className='dot dot4'></div>
                <div className='dot5'></div>
              </div>
              <h2 className='initialoading-heading'>One sec...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    } else if (this.state.section === 'contact') {
      let banner = null
      if (this.state.errorMessage !== null) {
        banner = (
          <div className='banner banner-hero'>
            <div className='container'>
              <p className='text'>{this.state.errorMessage}</p>
            </div>
          </div>
        )
      }
      return (
        <div>
          <HeaderView
            {...this.props}
            section='me'
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='wrapper'>
            {banner}
            <div className='searchview'>
              <div className='hero-box'>
                <Link className='searchview-research-btn' to='/?searchagain=1'>
                  <span className='fas fa-sync-alt'></span>
                  SEARCH AGAIN
                </Link>
              </div>
              <div className='container'>
                <div className='searchview-inner'>
                  <div className='details-box'>
                    <div className='details-box-preview'>
                      {
                        <p
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          #<strong>{this.state.item.chipnumber}</strong>
                        </p>
                      }
                      <img
                        className=''
                        alt={'Preview of #' + this.state.item.chipnumber}
                        src={
                          buildImagePreview ||
                          'https://cdn.chipndoodle.com/defaults/record.png'
                        }
                        title={buildImageTitle}
                      />
                      {buildImagePreview ? null : (
                        <div>
                          <p className=' searchview-provider-list-sidebar-red-label'>
                            No image was found for the pet
                          </p>
                        </div>
                      )}
                    </div>
                    <div className='details-box-meta'>
                      <div className='details-box-meta-textcontent'>
                        <h2 className=''>Your contact information</h2>

                        <p>
                          Leaving these details help a great deal and we will
                          show the details to others trying to find the pet.
                          Helping connect users and getting pets home.
                        </p>

                        <p>Please supply a few details:</p>
                      </div>
                      <div className='clearfix'></div>
                      <form
                        onSubmit={this.handleSubmit}
                        className='details-box-controls'
                      >
                        <div className='details-box-control'>
                          <div className='details-box-control-label'>
                            <label htmlFor='nickname'>Name</label>
                          </div>
                          <div className='details-box-control-inputs'>
                            <input type='text' ref={this.inputName} />
                          </div>
                          <div className='clearfix'></div>
                        </div>

                        <div className='details-box-control'>
                          <div className='details-box-control-label'>
                            <label htmlFor='nickname'>EMail</label>
                          </div>
                          <div className='details-box-control-inputs'>
                            <input type='text' ref={this.inputEmail} />
                          </div>
                          <div className='clearfix'></div>
                        </div>

                        <div className='details-box-control'>
                          <div className='details-box-control-label'>
                            <label htmlFor='nickname'>Phonenumber</label>
                          </div>
                          <div className='details-box-control-inputs'>
                            <input type='text' ref={this.inputPhonenumber} />
                          </div>
                          <div className='clearfix'></div>
                        </div>

                        <div className='details-box-control'>
                          <div className='details-box-control-label'>
                            <p>Where did you find the pet for example?</p>
                            <label htmlFor='nickname'>Message</label>
                          </div>
                          <div className='details-box-control-inputs'>
                            <textarea ref={this.inputMessage}></textarea>
                          </div>
                          <div className='clearfix'></div>
                        </div>
                      </form>

                      <div className='details-box-meta-textcontent'>
                        <p>
                          By submitting these details you grant us permission to
                          show these details when someone else looks for the
                          same microchip. Registered owners managing this
                          specific microchip details can also see these details.
                        </p>
                      </div>

                      <div className='details-box-toolbar'>
                        <a
                          href={'/searches/' + this.state.searchuid}
                          className='details-box-toolbar-action details-box-toolbar-action-right'
                          onClick={e => {
                            if (e) e.preventDefault()
                            this.handleSubmit()
                          }}
                        >
                          {this.state.submitting === true
                            ? 'SAVING...'
                            : 'SAVE MY DETAILS'}
                        </a>
                        <p className='details-box-toolbar-text'>
                          <a
                            href={'/searches/' + this.state.searchuid}
                            onClick={e => {
                              if (e) e.preventDefault()
                              this.setState({
                                section: 'empty'
                              })
                            }}
                          >
                            Back to information
                          </a>
                        </p>
                        <div className='clearfix'></div>
                      </div>
                    </div>
                    <div className='clearfix'></div>
                  </div>

                  <div className='clearfix'></div>
                  <div className='searchview-footer'></div>
                </div>
              </div>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    } else if (this.state.section === 'partial') {
      return (
        <div>
          <HeaderView
            {...this.props}
            section='me'
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='wrapper'>
            <div className='searchview'>
              <div className='hero-box'>
                <Link className='searchview-research-btn' to='/?searchagain=1'>
                  <span className='fas fa-sync-alt'></span>
                  SEARCH AGAIN
                </Link>
              </div>
              <div className='container'>
                <div className='searchview-inner'>
                  <div className='details-box'>
                    <div className='details-box-preview'>
                      {
                        <p
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          #<strong>{this.state.item.chipnumber}</strong>
                        </p>
                      }
                      <img
                        alt='Preview of record'
                        src={
                          buildImagePreview ||
                          'https://cdn.chipndoodle.com/defaults/record.png'
                        }
                        title={buildImageTitle}
                      />
                      {buildImagePreview ? null : (
                        <div>
                          <p className=' searchview-provider-list-sidebar-red-label'>
                            No image was found for the pet
                          </p>
                        </div>
                      )}
                    </div>
                    <div className='details-box-meta'>
                      <div className='details-box-meta-textcontent'>
                        <h2>
                          Registered in {this.state.item.responses.length}{' '}
                          database
                          {this.state.item.responses.length > 1 ? 's' : ''}
                        </h2>

                        <p>
                          We found the pet registered in the following databases
                          but did not manage to get any contact details.
                        </p>

                        <p>
                          Please get in touch with any of the following to find
                          the details of the owner:
                        </p>
                      </div>

                      <div className='searchview-provider-list searchview-provider-list-min'>
                        {this.state.item.responses.map(item => {
                          return (
                            <div className='searchview-provider-list-item'>
                              <a
                                rel='noopener noreferrer'
                                target='_blank'
                                href={item.database.website}
                                className='searchview-provider-list-item-inner'
                              >
                                <div className='searchview-provider-list-item-preview'>
                                  <img
                                    alt={item.name + ' Logo'}
                                    src={
                                      item.database.logo ||
                                      'https://cdn.chipndoodle.com/defaults/record.png'
                                    }
                                  />
                                </div>
                                <div className='searchview-provider-list-item-meta'>
                                  <h4 className='searchview-provider-list-item-meta-name'>
                                    {item.database.name}
                                  </h4>
                                  <p className='searchview-provider-list-item-meta-desc'>
                                    {this.formatDescription(item.database)}
                                  </p>
                                  <div className='clearfix'></div>
                                </div>
                                <div className='clearfix'></div>
                              </a>
                            </div>
                          )
                        })}
                      </div>

                      <div className='details-box-meta-textcontent'>
                        <h2>Why no contact details?</h2>

                        <p>
                          Some providers only provide limited integrations where
                          we are only able to check if they have the microchip
                          registered and not actual details around the
                          registered microchip.
                        </p>

                        <p>
                          All providers customer personal always ready to help,
                          so please give them a call and they will help you get
                          the pet home
                        </p>
                      </div>

                      <div className='clearfix'></div>
                    </div>
                    <div className='clearfix'></div>
                  </div>

                  <div className='clearfix'></div>
                  <div className='searchview-footer'></div>
                </div>
              </div>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    } else if (this.state.section === 'request') {
      let banner = null
      if (this.state.errorMessage !== null) {
        banner = (
          <div className='banner banner-hero'>
            <div className='container'>
              <p className='text'>{this.state.errorMessage}</p>
            </div>
          </div>
        )
      }
      return (
        <div>
          <HeaderView
            {...this.props}
            section='me'
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='wrapper'>
            {banner}
            <div className='searchview'>
              <div className='hero-box'>
                <Link className='searchview-research-btn' to='/?searchagain=1'>
                  <span className='fas fa-sync-alt'></span>
                  SEARCH AGAIN
                </Link>
              </div>
              <div className='container'>
                <div className='searchview-inner'>
                  <div className='details-box'>
                    <div className='details-box-preview'>
                      {
                        <p
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          #<strong>{this.state.item.chipnumber}</strong>
                        </p>
                      }
                      <img
                        alt='Preview of record'
                        src={
                          buildImagePreview ||
                          'https://cdn.chipndoodle.com/defaults/record.png'
                        }
                        title={buildImageTitle}
                      />
                      {buildImagePreview ? null : (
                        <div>
                          <p className=' searchview-provider-list-sidebar-red-label'>
                            No image was found for the pet
                          </p>
                        </div>
                      )}
                      <p className='searchview-certificate-price-prefix'>
                        Cost at
                      </p>
                      <p className='searchview-certificate-price'>
                        {this.state.certificateprice > 0
                          ? `R${this.state.certificateprice} ZAR`
                          : 'FREE'}
                      </p>
                      <p className='searchview-certificate-price-suffix'>
                        {this.state.certificateprice > 0 ? `(incl VAT) ` : ''}
                        per certificate.
                      </p>
                      {this.state.certificateprice > 0 ? (
                        <p className='searchview-certificate-price-terms'>
                          Once you click " Pay & Issue Certificate" we will
                          redirect you for payment. Upon payment (if any
                          required) we will email you the certificate in PDF
                          form that includes your receipt (proof-of-payment).
                        </p>
                      ) : (
                        <p className='searchview-certificate-price-terms'>
                          Once you click "Issue Certificate " we will email you
                          the certificate in PDF form.
                        </p>
                      )}
                    </div>
                    <div className='details-box-meta'>
                      <div className='details-box-meta-textcontent'>
                        <h2>ChipnDoodle Certificate</h2>

                        <p>
                          Our signed and verifyable certificate is the best way
                          to guarantee a incident free and secured import and
                          export. Featuring:
                        </p>

                        <form
                          onSubmit={this.handleCertificateSubmit}
                          className='details-box-controls'
                        >
                          {this.renderContactDetailsForm()}

                          <div className='details-box-control'>
                            <div className='details-box-control-label'>
                              <label htmlFor='nickname'>
                                Reason for Certificate
                              </label>
                            </div>
                            <div className='details-box-control-inputs'>
                              <div className='searchview-certificates-types'>
                                <a
                                  href={window.location.toString()}
                                  onClick={e => {
                                    e.preventDefault()
                                    this.setState({
                                      certificatetype: 'personal',
                                      certificateprice: 0
                                    })
                                  }}
                                  className={
                                    'searchview-certificates-type' +
                                    (this.state.certificatetype === 'personal'
                                      ? ' active'
                                      : '')
                                  }
                                >
                                  <div
                                    className={
                                      'searchview-certificates-type-inner' +
                                      (this.state.certificatetype === 'personal'
                                        ? ' active'
                                        : '')
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className='fas'
                                      icon={faUser}
                                    />
                                    <p className='searchview-certificates-type-text'>
                                      Personal
                                    </p>
                                    <p className='searchview-certificates-type-price'>
                                      FREE
                                    </p>
                                    <div className='clearfix'></div>
                                  </div>
                                </a>
                                <a
                                  href={window.location.toString()}
                                  onClick={e => {
                                    e.preventDefault()
                                    this.setState({
                                      certificatetype: 'import',
                                      certificateprice:
                                        this.state.allowfreecertificate === true
                                          ? 0
                                          : CERTIFICATE_PRICE_IN_ZAR,

                                      countryFromCode: this.state.countryToCode,
                                      countryFromName: this.state.countryToName,

                                      countryToCode: this.state.countryFromCode,
                                      countryToName: this.state.countryFromName
                                    })
                                  }}
                                  className={
                                    'searchview-certificates-type' +
                                    (this.state.certificatetype === 'import'
                                      ? ' active'
                                      : '')
                                  }
                                >
                                  <div
                                    className={
                                      'searchview-certificates-type-inner' +
                                      (this.state.certificatetype === 'personal'
                                        ? ' active'
                                        : '')
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className='fas'
                                      icon={faPlaneArrival}
                                    />
                                    <p className='searchview-certificates-type-text'>
                                      Importing
                                    </p>
                                    <p className='searchview-certificates-type-price'>
                                      {this.state.allowfreecertificate === true
                                        ? `FREE *`
                                        : `R${CERTIFICATE_PRICE_IN_ZAR}`}
                                    </p>
                                    <div className='clearfix'></div>
                                  </div>
                                </a>
                                <a
                                  href={window.location.toString()}
                                  onClick={e => {
                                    e.preventDefault()
                                    this.setState({
                                      certificatetype: 'export',
                                      certificateprice:
                                        this.state.allowfreecertificate === true
                                          ? 0
                                          : CERTIFICATE_PRICE_IN_ZAR,

                                      countryFromCode: this.state.countryToCode,
                                      countryFromName: this.state.countryToName,

                                      countryToCode: this.state.countryFromCode,
                                      countryToName: this.state.countryFromName
                                    })
                                  }}
                                  className={
                                    'searchview-certificates-type' +
                                    (this.state.certificatetype === 'export'
                                      ? ' active'
                                      : '')
                                  }
                                >
                                  <div
                                    className={
                                      'searchview-certificates-type-inner' +
                                      (this.state.certificatetype === 'personal'
                                        ? ' active'
                                        : '')
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className='fas'
                                      icon={faPlaneDeparture}
                                    />
                                    <p className='searchview-certificates-type-text'>
                                      Exporting
                                    </p>
                                    <p className='searchview-certificates-type-price'>
                                      {this.state.allowfreecertificate === true
                                        ? `FREE *`
                                        : `R${CERTIFICATE_PRICE_IN_ZAR}`}
                                    </p>
                                    <div className='clearfix'></div>
                                  </div>
                                </a>
                                <div className='clearfix'></div>
                              </div>
                            </div>
                            <div className='clearfix'></div>
                            {this.state.allowfreecertificate === true ? (
                              <p
                                style={{
                                  marginTop: 8,
                                  fontSize: 11,
                                  color: '#7F3584',
                                  fontWeight: 'bold'
                                }}
                              >
                                * this user account is allowed to generate free
                                certificates, normally priced at R
                                {CERTIFICATE_PRICE_IN_ZAR}
                              </p>
                            ) : null}
                            <div className='clearfix'></div>
                          </div>

                          {this.state.certificatetype !== 'personal' ? (
                            <div className='details-box-control'>
                              <div className='details-box-control-label'>
                                <label htmlFor='nickname'>
                                  Origin Country
                                  <span style={{ color: '#9d0208' }}> *</span>
                                </label>
                              </div>
                              <div className='details-box-control-inputs'>
                                <AsyncSelect
                                  noOptionsMessage={() =>
                                    'Start typing to search origin countries'
                                  }
                                  loadOptions={this.fetchCountriesForSelect}
                                  cacheOptions
                                  defaultOptions
                                  onChange={tt => {
                                    console.log(tt)
                                    this.setState({
                                      countryFromCode: tt.value,
                                      countryFromName: tt.label
                                    })
                                  }}
                                  value={
                                    this.state.countryFromName &&
                                    this.state.countryFromCode
                                      ? {
                                          value: this.state.countryFromCode,
                                          label: this.state.countryFromName
                                        }
                                      : null
                                  }
                                />
                              </div>
                              <div className='clearfix'></div>
                            </div>
                          ) : null}

                          {this.state.certificatetype !== 'personal' ? (
                            <div className='details-box-control'>
                              <div className='details-box-control-label'>
                                <label htmlFor='nickname'>
                                  Destination Country{' '}
                                  <span style={{ color: '#9d0208' }}> *</span>
                                </label>
                              </div>
                              <div className='details-box-control-inputs'>
                                <AsyncSelect
                                  noOptionsMessage={() =>
                                    'Start typing to search destination countries'
                                  }
                                  loadOptions={this.fetchCountriesForSelect}
                                  onChange={tt => {
                                    this.setState({
                                      countryToCode: tt.value,
                                      countryToName: tt.label
                                    })
                                  }}
                                  cacheOptions
                                  defaultOptions
                                  value={
                                    this.state.countryToName &&
                                    this.state.countryToCode
                                      ? {
                                          value: this.state.countryToCode,
                                          label: this.state.countryToName
                                        }
                                      : null
                                  }
                                />
                              </div>
                              <div className='clearfix'></div>
                            </div>
                          ) : null}

                          {this.state.certificatetype !== 'personal' ? (
                            <div className='details-box-control'>
                              <div className='details-box-control-label'>
                                <label htmlFor='nickname'>
                                  Animal Travel Agency (if any)
                                </label>
                              </div>
                              <div className='details-box-control-inputs'>
                                <AsyncSelect
                                  noOptionsMessage={() =>
                                    'Start typing to search known agencies'
                                  }
                                  loadOptions={this.fetchShippersForSelect}
                                  onChange={tt => {
                                    if (tt) {
                                      this.setState({
                                        shipperName: tt.label,
                                        shipperUID: tt.value
                                      })
                                    } else {
                                      this.setState({
                                        shipperName: null,
                                        shipperUID: null
                                      })
                                    }
                                  }}
                                  isClearable={true}
                                  cacheOptions
                                  defaultOptions
                                  value={
                                    this.state.shipperName
                                      ? {
                                          label: this.state.shipperName,
                                          value: this.state.shipperUID
                                        }
                                      : null
                                  }
                                />
                                <p className='searchview-shipper-suffx'>
                                  Didn't find your agency? Ask them to register
                                  by contacting{' '}
                                  <a href='mailto:info@chipndoodle.com'>
                                    info@chipndoodle.com
                                  </a>
                                </p>
                              </div>
                              <div className='clearfix'></div>
                            </div>
                          ) : null}

                          {/* <div className='details-box-control'>
                            <div className='details-box-control-label'>
                              <label htmlFor='nickname'>
                                Additional Details / Comments
                              </label>
                            </div>
                            <div className='details-box-control-inputs'>
                              <textarea
                                style={{
                                  minHeight: 150
                                }}
                                ref={this.inputCertificateComments}
                                placeholder={
                                  this.state.certificatetype !== 'personal'
                                    ? 'For example which method of transport and any other details'
                                    : 'For example "archival for record keeping"'
                                }
                              ></textarea>
                            </div>
                            <div className='clearfix'></div>
                          </div> */}
                          <ReCAPTCHA
                            ref={this.inputCaptcha}
                            sitekey={RECAPTCHA_SITE_KEY}
                            theme='light'
                            size='invisible'
                            onExpired={() => {}}
                            onErrored={() => {}}
                          />
                        </form>
                      </div>
                      <div className='details-box-toolbar'>
                        {this.state.submitting === true ? (
                          <div className='details-box-toolbar-action details-box-toolbar-action-right'>
                            Processing ...
                          </div>
                        ) : (
                          <Link
                            className='details-box-toolbar-action details-box-toolbar-action-right'
                            onClick={() => {
                              this.handleCertificateSubmit()
                            }}
                          >
                            {this.state.certificateprice > 0
                              ? `Pay (R${this.state.certificateprice}) & Issue Certificate`
                              : 'Issue Certificate'}
                          </Link>
                        )}
                        <p className='details-box-toolbar-text'></p>
                        <div className='clearfix'></div>
                      </div>

                      <div className='clearfix'></div>
                    </div>
                    <div className='clearfix'></div>
                  </div>

                  <div className='clearfix'></div>
                  <div className='searchview-footer'></div>
                </div>
              </div>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    } else if (this.state.section === 'empty') {
      let contactdetails = <p>No contacts details have been left</p>
      if (
        this.state.item &&
        this.state.item.contacts &&
        this.state.item.contacts.length > 0
      ) {
        contactdetails = this.state.item.contacts.map(contactItem => {
          return (
            <div>
              <h3>{contactItem.name}</h3>
              <p>Email: {contactItem.email}</p>
              <p>Phonenumber: {contactItem.phonenumber}</p>
              <p>{contactItem.message}</p>
              <p>
                Searched from {contactItem.city.name}, {contactItem.region.name}
                , {contactItem.country.name}
              </p>
              <p>Searched on {contactItem.created}</p>
            </div>
          )
        })
      }
      return (
        <div>
          <HeaderView
            {...this.props}
            section='me'
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='wrapper'>
            <div className='searchview'>
              <div className='hero-box'>
                <Link className='searchview-research-btn' to='/?searchagain=1'>
                  <span className='fas fa-sync-alt'></span>
                  SEARCH AGAIN
                </Link>
              </div>
              <div className='container'>
                <div className='searchview-inner'>
                  <div className='details-box'>
                    <div className='details-box-preview'>
                      {
                        <p
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          #<strong>{this.state.item.chipnumber}</strong>
                        </p>
                      }
                      <img
                        alt={'Preview of ' + this.state.searchuid}
                        src={
                          buildImagePreview ||
                          'https://cdn.chipndoodle.com/defaults/record.png'
                        }
                        title={buildImageTitle}
                      />
                      {buildImagePreview ? null : (
                        <div>
                          <p className='searchview-provider-list-sidebar-red-label'>
                            No image was found for the pet
                          </p>
                        </div>
                      )}
                    </div>
                    <div className='details-box-meta'>
                      <div className='details-box-toolbar details-box-toolbar-bad'>
                        <p className='details-box-toolbar-text'>
                          <span className='fas fa-info-circle'></span>
                          no records reported by any registered databases
                        </p>
                        <div className='clearfix'></div>
                      </div>

                      <div className='details-box-meta-textcontent'>
                        <h2>Now what?</h2>

                        <p>
                          We could not find the microchip through any of our
                          queries. Some of the providers seem to have been
                          blocking our requests for some reason. Here are
                          additional steps as to what you can do help find the
                          owner now:
                        </p>

                        <ul>
                          <li>
                            Check the microchip prefix to identify to a provider
                          </li>
                          <li>
                            Check if anyone left contact details for this
                            microchip
                          </li>
                          <li>Leave your details for others who search</li>
                          <li>Get in touch with local shelters</li>
                        </ul>

                        <h2>Check other regional search engines</h2>
                        <p>
                          The following are a list of search engines that you
                          could try searching with, some providers sometimes
                          block our requests and going straight to the search
                          engine and searching yields a result sometimes:
                        </p>
                        <div className='searchview-provider-engines-items'>
                          {this.state.item.engines.map(engineitem => {
                            return (
                              <a
                                href={engineitem.link}
                                className='searchview-provider-engines-item'
                                title={
                                  'Visit ' +
                                  engineitem.name +
                                  ' to search local records'
                                }
                              >
                                <div className='searchview-provider-engines-item-inner'>
                                  <span className='fas fa-arrow-circle-right'></span>
                                  <h3>{engineitem.name}</h3>
                                  <p>{engineitem.description}</p>
                                  <p>Maintained by {engineitem.maintainer}</p>
                                </div>
                              </a>
                            )
                          })}
                        </div>

                        <h2>Did anyone leave contact details?</h2>
                        <p>
                          What happens often is that owners search for pets too
                          even if they are not registered, with this in goal we
                          also allow you to leave contact details to get in
                          touch. These details will be shown when someone
                          searches for this microchip. This could help users
                          find each other, we do ask that you give the owner a
                          few days to try and see if anyone left their details
                          though!
                        </p>

                        {contactdetails}

                        {this.state.item.contactable === false ? (
                          <div>
                            <a
                              href={'/searches/' + this.state.searchuid}
                              className='search-add-contact-cta'
                              onClick={e => {
                                if (e) e.preventDefault()
                                this.setState({
                                  section: 'contact'
                                })
                              }}
                            >
                              Leave your details
                            </a>
                            <div className='clearfix'></div>
                          </div>
                        ) : null}
                        <div className='clearfix'></div>

                        <h2>Get in touch with local shelters</h2>
                        <p>
                          If nothing else works, do get in touch with a local
                          shelter. They are geared and prep to handle these
                          situations.
                        </p>
                      </div>

                      <div className='clearfix'></div>
                    </div>
                    <div className='clearfix'></div>
                  </div>

                  {this.renderSupplierBox()}
                  <div className='clearfix'></div>

                  <div className='clearfix'></div>
                  <div className='searchview-footer'></div>
                </div>
              </div>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    } else if (this.state.section === 'busy') {
      return (
        <div>
          <HeaderView
            {...this.props}
            section='me'
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='wrapper'>
            <div className='searchview'>
              <div className='hero-box'>
                <Link className='searchview-research-btn' to='/?searchagain=1'>
                  <span className='fas fa-sync-alt'></span>
                  SEARCH AGAIN
                </Link>
              </div>
              <div className='container'>
                <div className='searchview-inner'>
                  <div className='details-box'>
                    <div className='details-box-meta details-box-meta-full'>
                      <div className='details-box-meta-textcontent'>
                        <div className='searchview-busy-wrapper'>
                          <p className='searchview-busy-text'>
                            Searching, we are still going through all our
                            connected databases.
                          </p>
                          <div className='searchview-busy-circle'>
                            <CircularProgressbar
                              maxValue={this.state.item.count}
                              value={this.state.item.progress}
                              text={`${this.state.item.progress}/${this.state.item.count}`}
                            />
                          </div>
                          <p className='searchview-busy-text'>
                            We will show a result here as soon we have found
                            anything, while normally quick to start showing
                            results some databases might take a while to
                            respond. We will wait up to a max of 30 seconds.
                          </p>
                        </div>
                      </div>

                      <div className='clearfix'></div>
                    </div>
                    <div className='clearfix'></div>
                  </div>

                  <div className='clearfix'></div>
                  <div className='searchview-footer'></div>
                </div>
              </div>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    } else if (this.state.section === 'expired') {
      return (
        <div>
          <HeaderView
            {...this.props}
            section='me'
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='wrapper'>
            <div className='searchview searchview-bg'>
              <div className='container'>
                <div className='searchview-inner'>
                  <div className='searchview-dialog'>
                    <span className='fas fa-hourglass-half'></span>
                    <p className='searchview-dialog-heading'>
                      Access has expired
                    </p>
                    <p className='searchview-dialog-slogan'>
                      please search again to see newest data
                    </p>
                    <div className='clearfix'></div>
                  </div>

                  <div className='clearfix'></div>
                </div>
              </div>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    } else if (this.state.section === 'error') {
      return (
        <div>
          <HeaderView
            {...this.props}
            section='me'
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='wrapper'>
            <div className='searchview searchview-bg'>
              <div className='container'>
                <div className='searchview-inner'>
                  <div className='searchview-dialog'>
                    <span className='fas fa-bug'></span>
                    <p className='searchview-dialog-heading'>
                      Something went wrong
                    </p>
                    <p className='searchview-dialog-slogan'>
                      please try again in a few minutes
                    </p>
                    <div className='clearfix'></div>
                  </div>

                  <div className='clearfix'></div>
                </div>
              </div>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    } else if (this.state.section === 'notfound') {
      return (
        <div>
          <HeaderView
            {...this.props}
            section='me'
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='wrapper'>
            <div className='searchview searchview-bg'>
              <div className='container'>
                <div className='searchview-inner'>
                  <div className='searchview-dialog'>
                    <span className='fas fa-folder-open'></span>
                    <p className='searchview-dialog-heading'>Not Found</p>
                    <p className='searchview-dialog-slogan'>
                      No such search was found...
                    </p>
                    <div className='clearfix'></div>
                  </div>

                  <div className='clearfix'></div>
                </div>
              </div>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    }
    let userMenu = null
    if (this.state.user) {
      userMenu = <UserMenuView user={this.state.user}></UserMenuView>
    }
    let partnerBanner = null
    if (this.state.item.status === 'busy') {
      partnerBanner = (
        <div className='banner banner-hero'>
          <div className='container'>
            <p className='text'>
              Finished searching {this.state.item.progress} out of{' '}
              {this.state.item.count} databases, will show the information as we
              find it
            </p>
          </div>
        </div>
      )
    }
    return (
      <div>
        <HeaderView
          {...this.props}
          locality={this.state.locality}
          user={this.state.user}
          state={this.state}
        />
        {userMenu}
        <div className='wrapper'>
          {partnerBanner}
          <div className='searchview'>
            <div className='hero-box'>
              <Link className='searchview-research-btn' to='/?searchagain=1'>
                <span className='fas fa-sync-alt'></span>
                SEARCH AGAIN
              </Link>
            </div>
            <div className='container'>
              <div className='searchview-inner'>
                <div className='details-box'>
                  <div className='details-box-preview'>
                    {
                      <p
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        #<strong>{this.state.item.chipnumber}</strong>
                      </p>
                    }
                    <img
                      alt={'Preview of ' + this.state.searchuid}
                      src={
                        buildImagePreview ||
                        'https://cdn.chipndoodle.com/defaults/record.png'
                      }
                      title={buildImageTitle}
                    />
                    {buildImagePreview ? null : (
                      <div>
                        <p className='searchview-provider-list-sidebar-red-label'>
                          No image was found for the pet
                        </p>
                      </div>
                    )}
                    <p className='searchview-provider-list-sidebar-tagline'>
                      Was found in these databases:
                    </p>
                    <div className='searchview-provider-list'>
                      {this.state.item.responses.map(item => {
                        return (
                          <div className='searchview-provider-list-item'>
                            <Link
                              to={'/providers/' + item.database.slug}
                              target='_blank'
                              className='searchview-provider-list-item-inner'
                            >
                              <div className='searchview-provider-list-item-preview'>
                                <img
                                  alt={'Preview of ' + this.state.searchuid}
                                  src={
                                    item.database.logo ||
                                    'https://cdn.chipndoodle.com/defaults/record.png'
                                  }
                                />
                              </div>
                              <div className='searchview-provider-list-item-meta'>
                                <h4 className='searchview-provider-list-item-meta-name'>
                                  {item.database.name}
                                </h4>
                                <p className='searchview-provider-list-item-meta-desc'>
                                  {this.formatDescription(item.database)}
                                </p>
                                <div className='clearfix'></div>
                              </div>
                              <div className='clearfix'></div>
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className='details-box-meta'>
                    {this.getFieldValuesByKey('flagged').indexOf(true) !== -1 &&
                    S(
                      this.getFieldValuesByKey('message').join(', ')
                    ).isEmpty() === false ? (
                      <div className='details-box-toolbar details-box-toolbar-bad'>
                        <p className='details-box-toolbar-text'>
                          <span className='fas fa-info-circle'></span>
                          {this.getFieldValuesByKey('message').join(', ')}
                        </p>
                        <div className='clearfix'></div>
                      </div>
                    ) : null}

                    <div className='details-box-controls'>
                      {this.renderField({
                        field: 'label',
                        label: 'Name',
                        half: false
                      })}

                      <div className='clearfix'></div>

                      {this.renderField({
                        field: 'species',
                        label: 'Species',
                        half: true
                      })}

                      {this.renderField({
                        field: 'dob',
                        label: 'Date of Birth',
                        half: true,
                        limit: 1
                      })}

                      <div className='clearfix'></div>

                      {this.renderField({
                        field: 'breed',
                        label: 'Breed',
                        half: false
                      })}

                      <div className='clearfix'></div>

                      {this.renderField({
                        field: 'name',
                        label: 'Owner Name(s)',
                        half: false
                      })}

                      <div className='clearfix'></div>

                      {this.renderField({
                        field: 'email',
                        label: 'Owner E-Mail(s)',
                        half: false
                      })}

                      <div className='clearfix'></div>

                      {this.renderField({
                        field: 'tel',
                        label: 'Owner Tel(s)',
                        half: true
                      })}

                      {this.renderField({
                        field: 'cel',
                        label: 'Owner Cel(s)',
                        half: true
                      })}

                      <div className='clearfix'></div>
                    </div>
                    <div className='clearfix'></div>
                  </div>
                  <div className='clearfix'></div>
                </div>

                <div className='clearfix'></div>
                {this.renderCertificateBox()}
                <div className='clearfix'></div>
                {this.renderSupplierBox()}
                <div className='searchview-footer'></div>
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    )
  }
}

export default View
