import React, { Component } from 'react';
import S from 'string';
import moment from 'moment';
import './style.css';
import { Link } from 'react-router-dom';

class ProviderItem extends Component {
  formatURL = (text) => {
    text = text.toLowerCase();
    text = text.replace(/http:\/\/www\./gi, '');
    text = text.replace(/https:\/\/www\./gi, '');
    text = text.replace(/http:\/\//gi, '');
    text = text.replace(/https:\/\//gi, '');
    text = text.split('/')[0];
    return S(text).truncate(25, '..').s;
  }
  formatDescription = (item) => {
    if (item.website) {
      let text = item.website;
      text = text.toLowerCase();
      text = text.replace(/http:\/\/www\./gi, '');
      text = text.replace(/https:\/\/www\./gi, '');
      text = text.replace(/http:\/\//gi, '');
      text = text.replace(/https:\/\//gi, '');
      text = text.split('/')[0];
      return S(text).truncate(18, '..').s;
    } else {
      return 'since ' + moment(item.created).format('LLL')
    }
  }
  render() {
    const localitem = this.props.item;
    const itemClasses = [
      'provideritem-listing-item'
    ];
    if(this.props.size) {
      itemClasses.push('provideritem-listing-item-' + this.props.size)
    }
    if(localitem.status) {
      itemClasses.push('provideritem-listing-item-status-' + localitem.status)
    }
    return (
      <div key={"provideritem-provider-" + localitem.slug} className={itemClasses.join(' ')}>
        <Link className="provideritem-listing-item-inner" to={"/providers/" + localitem.slug}>
          <div className='provideritem-listing-item-inner-header'></div>
          <div className="provideritem-listing-item-preview">
            <div className="provideritem-listing-item-image">
              <img alt={"Logo of " + localitem.name} src={localitem.logo || 'https://cdn.chipndoodle.com/defaults/record.png'} />
            </div>
          </div>
          <div className="provideritem-listing-item-meta">
            <h3 className="provideritem-listing-item-meta-name">
              {S(localitem.name).truncate(16, '...').s}
              {
                localitem.flag ? <span className="provideritem-listing-item-meta-flag">({localitem.flag})</span> : null
              }
            </h3>
            <p className="provideritem-listing-item-meta-desc">{this.formatDescription(localitem)}</p>
          </div>
          <div className="clearfix"></div>
        </Link>
      </div>
    );
  }
}

export default ProviderItem;
